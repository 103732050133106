/* ApiList.module.css */
.libraryContainer {
    padding: 40px;
    background-color: #f8f9fa;
    text-align: center;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.libraryContainer header h1 {
    font-size: 2.5em;
    color: #2c3e50;
    margin-bottom: 20px;
}

.libraryContainer header p {
    color: #7f8c8d;
    font-size: 1.2em;
    margin-bottom: 30px;
}

.apiList {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 20px;
}

.apiCard {
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    transition: transform 0.3s, box-shadow 0.3s;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.apiCard:hover {
    transform: translateY(-3px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
}

.apiCard img {
    width: 80%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 15px;
}

.apiCard h2 {
    font-size: 1.2em;
    color: #2c3e50;
    margin-bottom: 10px;
    font-weight: 600;
}

.apiCard p {
    color: #7f8c8d;
    font-size: 1em;
    line-height: 1.6;
}

/* Lapozás stílusai */
.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    gap: 10px;
}

.paginationButton {
    background-color: #3498db;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.9em;
    font-weight: bold;
    transition: background-color 0.3s, box-shadow 0.3s;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
}

.paginationButton:hover {
    background-color: #2980b9;
    box-shadow: 0 5px 12px rgba(0, 0, 0, 0.15);
}

.paginationButton:disabled {
    background-color: #ccc;
    cursor: not-allowed;
    box-shadow: none;
}

.apiImage {
    width: 100%;
    height: 150px;
    object-fit: cover;
    object-position: center;
    border-radius: 8px 8px 0 0;
    margin-bottom: 10px;
}

/* Mobilbarát kialakítás */
@media (max-width: 1024px) {
    .apiList {
        grid-template-columns: repeat(3, 1fr); /* 3 oszlop tablet nézetben */
    }
}

@media (max-width: 768px) {
    .libraryContainer {
        padding: 20px;
    }

    .libraryContainer header h1 {
        font-size: 2em; /* Kisebb cím betűméret */
    }

    .libraryContainer header p {
        font-size: 1em; /* Kisebb bekezdés betűméret */
    }

    .apiList {
        grid-template-columns: repeat(2, 1fr); /* 2 oszlopos elrendezés mobilon */
        gap: 15px;
    }

    .apiCard {
        padding: 15px; /* Kisebb padding a kártyákon */
    }

    .apiCard h2 {
        font-size: 1.1em; /* Kisebb cím a kártyákon */
    }

    .apiCard p {
        font-size: 0.9em; /* Kisebb leírás a kártyákon */
    }
}

@media (max-width: 480px) {
    .libraryContainer {
        padding: 10px;
    }

    .libraryContainer header h1 {
        font-size: 1.8em;
    }

    .libraryContainer header p {
        font-size: 0.9em;
    }

    .apiList {
        grid-template-columns: 1fr; /* Egy oszlopos elrendezés kis képernyőn */
        gap: 10px;
    }

    .apiCard {
        padding: 10px;
    }

    .apiCard h2 {
        font-size: 1em;
    }

    .apiCard p {
        font-size: 0.8em;
    }
}
