.pageContainer {
    padding: 30px;
    background-color: #f9f9f9;
    max-width: 1200px;
    margin: 40px auto;
    text-align: center;
}

.pageContainer header {
    margin-bottom: 20px;
}

.pageContainer h1 {
    font-size: 2.5em;
    color: #333;
}

.pageContainer p {
    font-size: 1.1em;
    color: #666;
    margin-bottom: 30px;
}

.gamesList {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

.gameCard {
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    padding: 25px;
    width: calc(20% - 20px); /* 20% szélesség, hogy 5 férjen el egymás mellett, és figyelembe vegye a gap-et */
    max-width: 230px;
    text-align: center;
    text-decoration: none;
    color: #333;
    transition: box-shadow 0.3s ease;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.gameCard:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}


.gameImage {
    width: 160px; /* Közepes ikonméret */
    height: auto;
    margin-bottom: 15px;
    border-radius: 8px;
}

.gameCard h2 {
    font-size: 1.3em;
    color: #007bff;
    margin-bottom: 10px;
}

.gameCard p {
    font-size: 1em;
    color: #555;
}
/* Mobilbarát kialakítás */
@media (max-width: 1024px) {
    .gameCard {
        width: calc(33.33% - 20px); /* 3 oszlop tablet nézetben */
    }
}

@media (max-width: 768px) {
    .pageContainer {
        padding: 20px;
    }

    .pageContainer h1 {
        font-size: 2em; /* Kisebb cím betűméret */
    }

    .pageContainer p {
        font-size: 1em;
        margin-bottom: 20px;
    }

    .gamesList {
        gap: 15px;
    }

    .gameCard {
        width: calc(50% - 15px); /* 2 oszlop mobil nézetben */
        padding: 20px;
        max-width: 200px; /* Csökkentett max szélesség mobilon */
    }

    .gameCard h2 {
        font-size: 1.2em;
    }

    .gameCard p {
        font-size: 0.9em;
    }
}

@media (max-width: 480px) {
    .pageContainer {
        padding: 15px;
    }

    .pageContainer h1 {
        font-size: 1.8em;
    }

    .pageContainer p {
        font-size: 0.9em;
    }

    .gameCard {
        width: 100%; /* Egy oszlop kis képernyőn */
        padding: 15px;
        max-width: 100%;
    }

    .gameImage {
        width: 120px; /* Kisebb kép méret kis képernyőn */
        margin-bottom: 10px;
    }

    .gameCard h2 {
        font-size: 1.1em;
    }

    .gameCard p {
        font-size: 0.85em;
    }
}
