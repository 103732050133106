/* CategorySidebar.css */

.category-sidebar {
  width: 250px;
  background-color: #fff;
  padding: 5px;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.category-sidebar.fixed {
  position: fixed;
  top: 100px;
  right: 30px;
}

.category-sidebar.at-footer {
  position: absolute;
  bottom: 100px; /* Az alsó pozíciót rögzítjük a footer előtt */
  right: 30px;
}

.category-sidebar h2 {
  font-size: 1.5em;
  margin-bottom: 15px;
  color: #333;
  text-align: center;
}

.category-sidebar ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.category-sidebar ul li {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
  text-align: center;
}

.category-sidebar ul li:last-child {
  border-bottom: none;
}

.category-sidebar ul li a {
  color: #007bff;
  text-decoration: none;
  font-size: 1.1em;
}

.category-sidebar ul li a:hover {
  text-decoration: underline;
  color: #0056b3;
}
