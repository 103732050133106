.learn-container {
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    color: #333;
    text-align: center;
  }
  
  .learn-container h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #222;
  }
  
  .learn-container p {
    font-size: 1.2em;
    margin-bottom: 40px;
  }
  
  .article-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .article {
    padding: 15px;
    background-color: #2c2c2c;
    border-radius: 8px;
    border: 2px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: background-color 0.3s ease, border-color 0.3s ease;
    text-align: center;
  }
  
  .article-title {
    font-size: 1.6em;
    color: #007bff;
    margin: 0;
  }
  
  .article.open {
    background-color: #e8f4ff;
    border-color: #007bff;
  }
  
  .article-description {
    font-size: 1em;
    line-height: 1.6;
    color: #555;
    margin-top: 10px;
  }
  /* Learn.css */
.article-page-container {
    padding: 40px;
    max-width: 800px;
    margin: 40px auto;
    font-family: Arial, sans-serif;
    color: #333;
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .article-page-container h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #007bff;
  }
  
  .article-page-container p {
    font-size: 1.2em;
    line-height: 1.6;
    color: #555;
  }
  /* Mobilbarát kialakítás */
@media (max-width: 768px) {
  .learn-container {
    padding: 15px;
    max-width: 100%;
  }
  
  .learn-container h1 {
    font-size: 2em;
  }

  .learn-container p {
    font-size: 1em;
  }

  .article {
    padding: 10px;
  }

  .article-title {
    font-size: 1.4em;
  }

  .article-description {
    font-size: 0.9em;
  }

  .article-page-container {
    padding: 20px;
    max-width: 100%;
    margin: 20px auto;
  }

  .article-page-container h1 {
    font-size: 2em;
  }

  .article-page-container p {
    font-size: 1em;
  }
}

/* Extra kicsi képernyőkhöz, pl. mobiltelefonokhoz */
@media (max-width: 480px) {
  .learn-container h1 {
    font-size: 1.8em;
  }

  .learn-container p {
    font-size: 0.9em;
  }

  .article-title {
    font-size: 1.2em;
  }

  .article-description {
    font-size: 0.85em;
  }

  .article-page-container h1 {
    font-size: 1.8em;
  }

  .article-page-container p {
    font-size: 0.9em;
  }
}