.mini-articles {
  position: absolute;
  top: 100px;
  left: 270px;
  width: 250px;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  text-align: center;
}

.mini-articles h2 {
  text-align: center;
  margin-bottom: 20px;
}

.mini-article-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
}

.mini-article {
  text-align: center;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: white;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  width: 100%;
  height: 230px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.mini-article:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.mini-article img {
  width: 100%;
  height: 120px;
  object-fit: cover;
  border-radius: 8px;
}

.mini-article h3 {
  margin-top: 10px;
  font-size: 1.1em;
  color: #333;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.mini-article-link {
  text-decoration: none;
  color: inherit;
  display: block;
  width: 100%;
}

/* Mobilbarát kialakítás és mini articles rejtése mobilon */
@media (max-width: 768px) {
  /* Mini articles elrejtése mobilon */
  .mini-articles {
    display: none !important;
  }

  /* Cikkek rács elrendezése mobilon */
  .mini-article-grid {
    grid-gap: 10px;
  }

  /* Mini articles cikkek mobil nézetben */
  .mini-article {
    height: auto;
    padding: 10px;
  }

  /* Képek magasságának csökkentése mobilon */
  .mini-article img {
    height: 80px;
  }

  /* Cikk címek kisebb betűmérettel */
  .mini-article h3 {
    font-size: 1em;
  }
}
