/* SinglePost oldal specifikus stílusok */

.single-post {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.single-post img {
  max-width: 100%;
  height: auto;
  margin-bottom: 15px;
  border-radius: 8px;
}

.single-post h1 {
  text-align: center;
  font-size: 2.4em;
  margin-bottom: 20px;
}

.single-post p {
  font-size: 1.2em;
  line-height: 1.6;
  margin-bottom: 20px;
  color: #333;
}

.post-date {
  font-size: 0.9em;
  color: #888;
  margin-bottom: 10px;
}

/* Kategória oldalsáv stílusok */

.category-sidebar {
  position: fixed;
  top: 100px;
  right: 30px;
  width: 250px;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.category-sidebar h2 {
  font-size: 1.5em;
  margin-bottom: 15px;
  color: #333;
  text-align: center;
}

.category-sidebar ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.category-sidebar ul li {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
  text-align: center;
}

.category-sidebar ul li a {
  color: #007bff;
  text-decoration: none;
  font-size: 1.1em;
}

.category-sidebar ul li a:hover {
  text-decoration: underline;
  color: #0056b3;
}

/* Mobil nézet stílusok */
@media (max-width: 768px) {
  .single-post {
    padding: 15px;
  }

  .category-sidebar {
    display: none;
  }
}
