/* Alap layout */
.main-layout {
  display: flex;
  align-items: flex-start;
}

/* Sidebar stílusok */
.sidebar {
  position: fixed;
  top: 75px; /* 20px-szel lejjebb helyezve */
  left: -245px; /* Alapértelmezett helyzet */
  width: 245px;
  background-color: #ffffff; /* Sima fehér háttér */
  overflow-y: auto;
  transition: transform 0.3s ease, left 0.3s ease;
  transform: translateX(-100%);
  z-index: 200;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15); /* Enyhe árnyék */
  border-radius: 12px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
  max-height: calc(100vh - 100px); /* Footer-hez igazítva */
}

.sidebar.open {
  transform: translateX(0); /* Megnyitott állapot */
  left: 0; /* Megnyitott állapotban balra igazítva */
}

body.dark-mode .sidebar {
  background-color: #2a2a2a;
  color: #ffffff;
}

/* Close gomb a Sidebar-ban */
.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  background: none;
  border: none;
  color: #000000;
  cursor: pointer;
}

.close-btn:hover {
  color: #333;
}

body.dark-mode .close-btn {
  color: #ffffff;
}

/* Sidebar címsor */
.sidebar h3 {
  padding-left: 5px;
  font-size: 1.3em;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

body.dark-mode .sidebar h3 {
  color: #ffffff;
  border-bottom: 1px solid #555;
}

/* Sidebar listaelemek */
.sidebar ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.sidebar ul li {
  padding: 12px 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.sidebar ul li:hover {
  background: rgba(122, 119, 119, 0.1);
  border-radius: 8px;
}

body.dark-mode .sidebar ul li:hover {
  background: rgba(83, 83, 83, 0.2);
}

.network-name {
  font-weight: bold;
  color: #333;
  font-size: 1em;
}

body.dark-mode .network-name {
  color: #ffffff;
}

/* Linkek stílusai */
.network-link {
  color: #0c0c0c;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.2s, transform 0.2s;
}

.network-link:hover {
  color: #121213;
  transform: translateX(4px);
}

body.dark-mode .network-link {
  color: #1a1b1b;
}

body.dark-mode .network-link:hover {
  color: #161616;
}

/* Statusz címkék */
.status {
  font-size: 0.8em;
  padding: 4px 10px;
  border-radius: 12px;
  color: #fff;
  font-weight: bold;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.available {
  background-color: #28a745;
}

.coming-soon {
  background-color: #6c757d;
}

/* Tartalom beállítás */
.content {
  flex: 1;
  padding: 20px;
  margin-left: 245px;
}

.sidebar.open + .content {
  margin-left: 245px;
}

/* Mobil nézethez a Sidebar eltüntetése */
@media (max-width: 768px) {
  .sidebar {
    left: -100%;
    transform: translateX(-100%);
  }

  .sidebar.open {
    transform: translateX(0);
    left: 0;
    width: 80%;
    max-width: 240px;
  }

  .content {
    margin-left: 0;
    width: 100%;
  }
}
