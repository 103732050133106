/* Library.module.css */
.libraryContainer {
    padding: 40px;
    background-color: #f8f9fa;
    text-align: center;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.libraryContainer header h1 {
    font-size: 2.5em;
    color: #2c3e50;
    margin-bottom: 20px;
}

.libraryContainer header p {
    color: #7f8c8d;
    font-size: 1.2em;
    margin-bottom: 30px;
}

.categoryList {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
    justify-content: center;
}

.categoryCard {
    background-color: #ffffff;
    border: 1px solid #3498db;
    border-radius: 10px;
    padding: 20px;
    width: 100%;
    text-align: center;
    cursor: pointer;
    transition: transform 0.3s, box-shadow 0.3s;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.categoryCard:hover {
    transform: translateY(-3px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
    background-color: #ecf6fd;
}

.categoryCard h2 {
    color: #3498db;
    font-size: 1.3em;
    font-weight: bold;
    margin: 0;
}

/* Mobilbarát kialakítás */
@media (max-width: 768px) {
    .libraryContainer {
        padding: 20px;
    }

    .libraryContainer header h1 {
        font-size: 2em; /* Kisebb cím betűméret */
    }

    .libraryContainer header p {
        font-size: 1em; /* Kisebb bekezdés betűméret */
    }

    .categoryList {
        gap: 15px;
    }

    .categoryCard {
        padding: 15px; /* Kisebb padding a kártyákon */
    }

    .categoryCard h2 {
        font-size: 1.2em; /* Kisebb cím a kártyákon */
    }
}

@media (max-width: 480px) {
    .libraryContainer {
        padding: 10px;
    }

    .libraryContainer header h1 {
        font-size: 1.8em;
    }

    .libraryContainer header p {
        font-size: 0.9em;
    }

    .categoryList {
        grid-template-columns: 1fr; /* Egyoszlopos elrendezés kis képernyőn */
        gap: 10px;
    }

    .categoryCard {
        padding: 10px;
    }

    .categoryCard h2 {
        font-size: 1.1em;
    }
}
