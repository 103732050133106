.footer {
    background-color: #1a1a1a;
    color: #fff;
    padding: 40px 20px;
    display: flex;
    justify-content: center;
    gap: 60px;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 100px;
}

.footer-column {
    flex: 1;
    margin: 10px;
    min-width: 150px;
    text-align: center;
}

.footer-column h3 {
    color: #fff;
    font-size: 18px;
    margin-bottom: 15px;
    text-transform: uppercase;
}

.footer-column a {
    color: #aaa;
    display: block;
    margin-bottom: 10px;
    text-decoration: none;
    font-size: 14px;
}

.footer-column a:hover {
    color: #ffd700;
}

.social-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #aaa;
    text-decoration: none;
    font-size: 16px;
    margin-bottom: 15px;
    transition: color 0.3s ease;
}

.social-link:hover {
    color: #ffd700;
}

.social-icon {
    width: 40px; /* Ikon mérete */
    height: 40px;
    margin-bottom: 5px; /* Hézag az ikon és a szöveg között */
    border-radius: 50%; /* Kör alakú ikon */
    background-color: #333; /* Háttérszín az ikon mögött */
    padding: 5px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3); /* Árnyék */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    margin-right: 5px; /* Az ikon és a szöveg közötti távolság 5 pixel */
}

.social-icon:hover {
    transform: scale(1.1); /* Kicsit nagyít az ikonon hover állapotban */
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.5);
}
/* Mobilbarát kialakítás */
@media (max-width: 768px) {
    .footer {
        flex-direction: column; /* Oszlopos elrendezés mobil nézetben */
        gap: 20px;
        padding: 30px 15px; /* Csökkentett padding */
    }

    .footer-column {
        text-align: center;
        margin: 5px 0; /* Kevesebb margin a footer elemek között */
    }

    .footer-column h3 {
        font-size: 16px; /* Kisebb cím betűméret */
    }

    .footer-column a {
        font-size: 13px; /* Kisebb link betűméret */
        margin-bottom: 8px; /* Kevesebb hely a linkek között */
    }

    .social-link {
        flex-direction: row; /* Ikon és szöveg egymás mellett */
        align-items: center;
        justify-content: center;
        font-size: 14px;
    }

    .social-icon {
        width: 30px; /* Kisebb ikon méret */
        height: 30px;
        margin-right: 8px; /* Kevesebb távolság az ikon és a szöveg között */
    }
}

@media (max-width: 480px) {
    .footer {
        padding: 20px 10px; /* További padding csökkentés */
    }

    .footer-column h3 {
        font-size: 15px;
    }

    .footer-column a {
        font-size: 12px;
    }

    .social-icon {
        width: 25px;
        height: 25px;
    }
}
