.crypto-ticker {
    width: 100%;
    background-color: #f8f9fa;
    overflow: hidden;
    border: 1px solid #ddd;
    padding: 10px 0;
    margin-bottom: 20px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ticker-track {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    animation: ticker 30s linear infinite;
    white-space: nowrap;
}

.ticker-item {
    display: flex;
    align-items: center;
    margin-right: 40px;
    font-size: 14px;
    padding: 5px 10px;
    background-color: #eef6ed;
    border-radius: 5px;
}

.coin-icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    position: relative;
    top: 3px;
}

.positive {
    color: green;
    padding: 5px 20px; /* Több padding a szöveg körül */
    background-color: rgba(0, 255, 0, 0.2); /* Egy világosabb zöld háttér */
    border-radius: 10px; /* Lekerekítettebb sarkok */
}
.negative {
    color: red;
    padding: 5px 20px; /* Több padding a piros rész szélesítéséhez */
    background-color: rgba(255, 0, 0, 0.2); /* Világosabb piros háttér */
    border-radius: 10px; /* Lekerekített sarkok */
}

.negative {
    color: red;
    margin-left: 5px;
}

@keyframes ticker {
    0% {
        transform: translateX(60%);
    }
    100% {
        transform: translateX(-100%);
    }
}
@media (max-width: 768px) {
    .crypto-ticker {
      font-size: 12px;
    }
    .ticker-item {
      margin-right: 20px;
      font-size: 12px;
    }
  }
  