/* CryptoNews oldal specifikus stílusok */

.crypto-news-container {
  padding: 40px;
  max-width: 800px;
  margin: 0 auto;
  font-family: 'Arial', sans-serif;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.crypto-news-title {
  text-align: center;
  font-size: 2.8em;
  font-weight: bold;
  margin-bottom: 40px;
  color: #333;
}

.post-list {
  display: grid;
  grid-template-columns: 1fr;
  gap: 40px;
}

.blog-post {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.blog-post:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.post-title {
  font-size: 1.8em;
  margin-bottom: 15px;
  color: #000;
}

.post-excerpt {
  font-size: 1.2em;
  line-height: 1.6;
  color: #555;
  margin-bottom: 20px;
}

.read-more {
  display: inline-block;
  padding: 8px 15px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.read-more:hover {
  background-color: #0056b3;
}

/* Pagination */
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.page-button {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  padding: 10px 20px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.page-button.active {
  background-color: #007bff;
  color: white;
}

.page-button:hover {
  background-color: #007bff;
  color: white;
}

/* Navbar balra igazítása */
.navbar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}

.left-section {
  display: flex;
  align-items: center;
}

.btn.btn-secondary {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

/* Mobilbarát kialakítás */
@media (max-width: 768px) {
  /* Container és cím méretének csökkentése */
  .crypto-news-container {
    padding: 20px;
    max-width: 100%;
    margin: 0 10px;
  }

  .crypto-news-title {
    font-size: 2em;
    margin-bottom: 20px;
  }

  /* Eltávolítjuk a valós adatú árakat mobil nézetben */
  .crypto-ticker {
    display: none;
  }

  /* Blog post kártya méretének és margójának csökkentése */
  .blog-post {
    padding: 15px;
    margin-bottom: 20px;
  }

  .post-title {
    font-size: 1.5em;
  }

  .post-excerpt {
    font-size: 1em;
  }

  /* Navigációs gombok méretének csökkentése mobilon */
  .page-button {
    padding: 8px 12px;
    margin: 0 3px;
  }

  /* Navbar mobilon */
  .navbar {
    padding: 5px;
  }

  .btn.btn-secondary {
    padding: 5px 10px;
    font-size: 0.9em;
  }
}
/* CryptoNews oldal specifikus stílusok */

/* ... korábbi stílusok ... */

/* Mobilbarát kialakítás */
@media (max-width: 768px) {
  /* "Networks" rész és gomb eltávolítása mobilnézetben */
  .crypto-ticker, 
  .networks,
  .navbar .btn.btn-secondary {
    display: none !important;
  }

  /* Container és cím méretének csökkentése */
  .crypto-news-container {
    padding: 20px;
    max-width: 100%;
    margin: 0 10px;
  }

  .crypto-news-title {
    font-size: 2em;
    margin-bottom: 20px;
  }

  /* Blog post kártya méretének és margójának csökkentése */
  .blog-post {
    padding: 15px;
    margin-bottom: 20px;
  }

  .post-title {
    font-size: 1.5em;
  }

  .post-excerpt {
    font-size: 1em;
  }

  /* Navigációs gombok méretének csökkentése mobilon */
  .page-button {
    padding: 8px 12px;
    margin: 0 3px;
  }

  /* Navbar mobilon */
  .navbar {
    padding: 5px;
  }
}
