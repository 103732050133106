.converter-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    font-family: Arial, sans-serif;
  }
  
  .converter-container h1 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
  }
  
  .converter-options {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-bottom: 20px;
  }
  
  .currency-select, .crypto-select {
    font-size: 16px;
    padding: 5px;
  }
  
  .toggle-button {
    font-size: 24px;
    cursor: pointer;
    margin: 0 10px;
    color: #007bff;
  }
  
  .converter-inputs {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    margin-bottom: 20px;
  }
  
  .converter-inputs input {
    font-size: 16px;
    padding: 10px;
    width: 150px;
    border: 1px solid #ccc;
    border-radius: 4px;
    text-align: right;
  }
  
  .converter-inputs span {
    font-size: 16px;
    font-weight: bold;
  }
  
  .rate-info {
    font-size: 14px;
    color: #555;
  }
  /* Mobilbarát kialakítás */
@media (max-width: 768px) {
  .converter-container {
    padding: 15px;
    max-width: 90%;
  }

  .converter-container h1 {
    font-size: 20px;
  }

  .converter-options {
    flex-direction: column;
    gap: 10px;
  }

  .converter-inputs {
    flex-direction: column;
    gap: 10px;
  }

  .converter-inputs input {
    width: 100%;
    text-align: right;
  }

  .toggle-button {
    font-size: 20px;
    margin: 0 5px;
  }
}

/* Extra kicsi képernyőkhöz, pl. mobiltelefonokhoz */
@media (max-width: 480px) {
  .converter-container h1 {
    font-size: 18px;
  }

  .currency-select, .crypto-select, .converter-inputs input {
    font-size: 14px;
    padding: 8px;
  }

  .converter-inputs span {
    font-size: 14px;
  }

  .toggle-button {
    font-size: 18px;
  }

  .rate-info {
    font-size: 12px;
  }
}