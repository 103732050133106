.pageContainer {
    padding: 30px;
    background-color: #f9f9f9;
    max-width: 1200px;
    margin: 40px auto;
    text-align: center;
}

.pageContainer header {
    margin-bottom: 20px;
}

.pageContainer h1 {
    font-size: 2.5em;
    color: #333;
}

.pageContainer p {
    font-size: 1.1em;
    color: #666;
    margin-bottom: 30px;
}

.exchangesList {
    display: grid;
    grid-template-columns: repeat(5, 1fr); /* 5 oszlop, amely automatikusan szélesedik */
    gap: 20px;
    justify-items: center;
    width: 100%;
}

.exchangeCard {
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    padding: 25px;
    width: 100%; /* A kártya szélessége igazodik az oszlop szélességéhez */
    max-width: 230px; /* Maximális szélesség korlátozása nagy képernyőkön */
    text-align: center;
    text-decoration: none;
    color: #333;
    transition: box-shadow 0.3s ease;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.exchangeCard:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}

.exchangeImage {
    width: 160px; /* Közepes ikonméret */
    height: auto;
    margin-bottom: 15px;
    border-radius: 8px;
}

.exchangeCard h2 {
    font-size: 1.3em;
    color: #007bff;
    margin-bottom: 10px;
}

.exchangeCard p {
    font-size: 1em;
    color: #555;
}

/* Mobilbarát kialakítás */
@media (max-width: 1024px) {
    .exchangesList {
        grid-template-columns: repeat(3, 1fr); /* 3 oszlop tablet nézetben */
        gap: 15px;
    }
}

@media (max-width: 768px) {
    .pageContainer {
        padding: 20px;
    }

    .pageContainer h1 {
        font-size: 2em; /* Kisebb cím betűméret */
    }

    .pageContainer p {
        font-size: 1em;
        margin-bottom: 20px;
    }

    .exchangesList {
        grid-template-columns: repeat(2, 1fr); /* 2 oszlop mobil nézetben */
        gap: 15px;
    }

    .exchangeCard {
        padding: 20px;
        max-width: 200px; /* Csökkentett max szélesség mobilon */
    }

    .exchangeCard h2 {
        font-size: 1.2em;
    }

    .exchangeCard p {
        font-size: 0.9em;
    }
}

@media (max-width: 480px) {
    .pageContainer {
        padding: 15px;
    }

    .pageContainer h1 {
        font-size: 1.8em;
    }

    .pageContainer p {
        font-size: 0.9em;
    }

    .exchangesList {
        grid-template-columns: 1fr; /* Egy oszlop kis képernyőn */
        gap: 10px;
    }

    .exchangeCard {
        padding: 15px;
        max-width: 100%; /* Kártyák teljes szélesség kis képernyőn */
    }

    .exchangeImage {
        width: 120px; /* Kisebb kép méret kis képernyőn */
        margin-bottom: 10px;
    }

    .exchangeCard h2 {
        font-size: 1.1em;
    }

    .exchangeCard p {
        font-size: 0.85em;
    }
}
