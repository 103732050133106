.detailContainer {
    padding: 30px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 40px auto;
    text-align: left;
}

.detailImage {
    width: 100%;
    max-width: 200px;
    height: auto;
    margin-bottom: 20px;
    border-radius: 10px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.detailContainer h1 {
    font-size: 2em;
    color: #2c3e50;
    text-align: center;
    margin-top: 0;
    margin-bottom: 10px;
}

.detailDescription {
    color: #7f8c8d;
    font-size: 1.2em;
    margin-bottom: 15px;
    text-align: center;
}

.detailContent {
    color: #4a4a4a;
    font-size: 1.1em;
    line-height: 1.6;
    margin-bottom: 20px;
}

.detailContent h2 {
    color: #3498db;
    font-size: 1.3em;
    margin-top: 15px;
    margin-bottom: 8px;
}

.detailContent ul {
    list-style-type: disc;
    margin-left: 20px;
    margin-bottom: 15px;
}

.detailContent ul li {
    margin-bottom: 8px;
}

.detailLink {
    display: inline-block;
    margin-top: 15px;
    color: #3498db;
    text-decoration: none;
    font-weight: bold;
    font-size: 1.1em;
    text-align: center;
    transition: color 0.3s;
}

.detailLink:hover {
    color: #2980b9;
    text-decoration: underline;
}

/* Mobilbarát kialakítás */
@media (max-width: 768px) {
    .detailContainer {
        padding: 20px;
        margin: 20px auto;
    }

    .detailImage {
        max-width: 150px; /* Kisebb kép méret mobil nézetben */
        margin-bottom: 15px;
    }

    .detailContainer h1 {
        font-size: 1.8em; /* Kisebb cím betűméret */
    }

    .detailDescription {
        font-size: 1em; /* Kisebb leírás betűméret */
        margin-bottom: 10px;
    }

    .detailContent {
        font-size: 1em;
        line-height: 1.5; /* Szorosabb sortáv mobilon */
    }

    .detailContent h2 {
        font-size: 1.2em;
    }

    .detailContent ul {
        margin-left: 15px;
        padding-left: 15px;
    }

    .detailLink {
        font-size: 1em;
    }
}

@media (max-width: 480px) {
    .detailContainer {
        padding: 15px;
        margin: 15px auto;
    }

    .detailImage {
        max-width: 120px;
        margin-bottom: 10px;
    }

    .detailContainer h1 {
        font-size: 1.6em;
    }

    .detailDescription {
        font-size: 0.9em;
    }

    .detailContent {
        font-size: 0.95em;
    }

    .detailContent h2 {
        font-size: 1.1em;
    }

    .detailLink {
        font-size: 0.95em;
    }
}
