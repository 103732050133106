/* Alap stílusok */
body {
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
    margin: 0;
    padding: 0;
    min-height: 100vh;
  }
  
  /* Dark mode stílusok */
  body.dark-mode {
    background-color: #1e1e1e;
    color: #ffffff;
  }
  
  /* Navbar stílusai */
  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: transparent;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1000;
  }
  
  /* Bal és jobb szekció */
  .navbar .left-section,
  .navbar .right-section {
    display: flex;
    align-items: center;
  }
  
  .navbar .btn {
    margin: 0 5px;
  }
  
  .navbar .metamask-connect {
    display: flex;
    align-items: center;
  }
  
  .navbar .metamask-logo {
    width: 24px;
    height: 24px;
  }
  
  /* MetaMask gomb stílusai */
  .metamask-connect .btn {
    background-color: #444444;
    color: white;
  }
  
  .metamask-connect .btn:hover {
    background-color: #555555;
  }
  
  body.dark-mode .metamask-connect .btn {
    background-color: #444444;
    color: white;
  }
  
  body.dark-mode .metamask-connect .btn:hover {
    background-color: #555555;
  }
  
  /* Dark Mode gomb */
  .dark-mode-toggle {
    position: fixed;
    bottom: 100px;
    right: 10px;
    z-index: 1000;
  }
  
  .dark-mode-toggle button {
    font-size: 16px;
    padding: 10px 20px;
    background-color: #6c757d;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .dark-mode-toggle button:hover {
    background-color: #5a6268;
  }
  
  body.dark-mode .dark-mode-toggle button {
    background-color: #444;
  }
  
  body.dark-mode .dark-mode-toggle button:hover {
    background-color: #555;
  }
  
  /* Base logo section */
  .base-logo-section {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .base-logo-section img {
    width: 50px;
    height: auto;
  }
  
  .base-logo-section p {
    font-size: 1rem;
    color: #333;
  }
  
  body.dark-mode .base-logo-section p {
    color: #ffffff;
  }
  
  /* Card stílus */
  .card {
    background-color: white;
    border-radius: 10px;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
  }
  
  body.dark-mode .card {
    background-color: #2a2a2a;
    color: #ffffff;
  }
  
  h1 {
    color: #333;
  }
  
  body.dark-mode h1 {
    color: #ffffff;
  }
  
  /* Form stílusok */
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #000;
  }
  
  body.dark-mode label {
    color: #ffffff;
  }
  
  input,
  select,
  textarea {
    width: 100%;
    padding: 10px;
    margin: 5px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    color: #000;
  }
  
  body.dark-mode input,
  body.dark-mode select,
  body.dark-mode textarea {
    background-color: #3a3a3a;
    color: #ffffff;
    border: 1px solid #555;
  }
  
  /* Button stílus */
  button {
    background-color: #28a745;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  button:hover {
    background-color: #218838;
  }
  
  .btn-secondary {
    background-color: #6c757d;
  }
  
  .btn-secondary:hover {
    background-color: #5a6268;
  }
  
  p {
    margin-top: 20px;
    font-size: 18px;
    color: #555;
  }
  
  body.dark-mode p {
    color: #cccccc;
  }
  
  /* Accordion styles */
  .accordion-button {
    background-color: transparent;
    color: #000;
  }
  
  .accordion-button:not(.collapsed) {
    background-color: #e7e7e7;
    color: #000;
  }
  
  .accordion-body {
    background-color: #f8f9fa;
    color: #000;
  }
  
  .accordion-item {
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  body.dark-mode .accordion-button {
    background-color: #2a2a2a;
    color: #ffffff;
  }
  
  body.dark-mode .accordion-body {
    background-color: #2a2a2a;
    color: #ffffff;
  }
  
  body.dark-mode .accordion-item {
    background-color: #2a2a2a;
    border: 1px solid #555;
  }
  
  /* Social media icons */
  .social-media-icons {
    position: fixed;
    bottom: 10px;
    right: 10px;
    display: flex;
    gap: 20px;
  }
  
  .social-media-icons .social-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .social-media-icons img {
    width: 30px;
    height: 30px;
  }
  /* Mobilbarát elrendezés 768px-nél kisebb kijelzőkre (FAQ és Dark Mode nélkül) */
@media (max-width: 768px) {
  .navbar {
      flex-direction: column; /* Oszlopos elrendezés mobil nézetben */
      padding: 10px 20px;
  }

  .navbar .btn {
      margin: 5px 0;
  }

  .base-logo-section {
      margin-bottom: 20px;
  }

  .card {
      width: 90%; /* Szélesség csökkentése mobil nézeten */
      margin: 20px auto;
      padding: 15px;
  }

  h1 {
      font-size: 24px; /* Kisebb betűméret mobil nézeten */
  }

  .form-group label {
      font-size: 14px; /* Kisebb cím betűméret */
  }

  input,
  select,
  textarea {
      font-size: 14px; /* Kisebb betűméret az űrlap elemeiben */
  }

  button {
      font-size: 14px;
      padding: 8px 12px;
  }

  .social-media-icons {
      bottom: 15px; /* Az ikonok lejjebb helyezkednek el mobil nézeten */
      right: 15px;
      gap: 15px;
  }

  /* FAQ és Dark Mode gombok elrejtése mobilon */
  .faq-section,
  .dark-mode-toggle {
      display: none !important;
  }
}

/* Mobilbarát elrendezés 480px-nél kisebb kijelzőkre (FAQ és Dark Mode nélkül) */
@media (max-width: 480px) {
  .navbar {
      padding: 10px; /* Kisebb padding mobil nézeten */
  }

  .base-logo-section img {
      width: 40px; /* Kisebb logó méret */
  }

  .card {
      width: 100%; /* Teljes szélesség mobil nézeten */
  }

  h1 {
      font-size: 20px; /* Még kisebb betűméret mobil nézeten */
  }

  .form-group label,
  input,
  select,
  textarea {
      font-size: 12px; /* További méretcsökkentés az űrlap elemeknél */
  }

  button {
      font-size: 12px;
      padding: 8px 10px;
  }

  .social-media-icons .social-icon {
      flex-direction: row; /* Ikon és szöveg egymás mellett mobil nézeten */
      align-items: center;
      font-size: 12px;
  }

  .social-media-icons img {
      width: 25px; /* Kisebb ikonméret mobil nézeten */
      height: 25px;
  }
}
