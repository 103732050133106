/* NftTrading.module.css */

.pageContainer {
    padding: 30px;
    background-color: #f9f9f9;
    max-width: 1200px;
    margin: 40px auto;
    text-align: center;
}

.pageContainer header {
    margin-bottom: 20px;
}

.pageContainer h1 {
    font-size: 2.5em;
    color: #333;
}

.pageContainer p {
    font-size: 1.1em;
    color: #666;
    margin-bottom: 30px;
}

.platformList {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Automatikus oszlopok, legalább 200px szélességgel */
    gap: 20px;
    justify-items: center;
    width: 100%;
}

.platformCard {
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    text-decoration: none;
    color: #333;
    transition: box-shadow 0.3s ease, transform 0.3s ease;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 240px; /* Maximális szélesség */
}

.platformCard:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
    transform: translateY(-5px); /* Enyhe mozgás hover állapotban */
}

.platformImage {
    width: 140px;
    height: auto;
    margin-bottom: 15px;
    border-radius: 8px;
}

.platformCard h2 {
    font-size: 1.3em;
    color: #007bff;
    margin-bottom: 10px;
}

.platformCard p {
    font-size: 1em;
    color: #555;
}
/* Mobilbarát kialakítás */
@media (max-width: 1024px) {
    .platformList {
        grid-template-columns: repeat(3, 1fr); /* 3 oszlop tablet nézetben */
        gap: 15px;
    }
}

@media (max-width: 768px) {
    .pageContainer {
        padding: 20px;
    }

    .pageContainer h1 {
        font-size: 2em; /* Kisebb cím betűméret */
    }

    .pageContainer p {
        font-size: 1em; /* Kisebb leírás betűméret */
        margin-bottom: 20px;
    }

    .platformList {
        grid-template-columns: repeat(2, 1fr); /* 2 oszlop mobil nézetben */
        gap: 15px;
    }

    .platformCard {
        padding: 15px;
        max-width: 200px; /* Maximális szélesség csökkentve mobilon */
    }

    .platformCard h2 {
        font-size: 1.2em;
    }

    .platformCard p {
        font-size: 0.9em;
    }
}

@media (max-width: 480px) {
    .pageContainer {
        padding: 15px;
    }

    .pageContainer h1 {
        font-size: 1.8em;
    }

    .pageContainer p {
        font-size: 0.9em;
    }

    .platformList {
        grid-template-columns: 1fr; /* Egy oszlop kis képernyőn */
        gap: 10px;
    }

    .platformCard {
        padding: 10px;
        max-width: 180px; /* További csökkentés a maximális szélességen */
    }

    .platformCard h2 {
        font-size: 1.1em;
    }

    .platformCard p {
        font-size: 0.85em;
    }

    .platformImage {
        width: 120px; /* Kisebb kép méret kis képernyőn */
        margin-bottom: 10px;
    }
}
