.homepage-container {
  display: flex;
  flex-direction: column;
  font-family: 'Arial', sans-serif;
  color: #333;
  background-image: url('/src/meme-coin-logo.PNG');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  min-height: 100vh;
}
.content-wrapper {
  transform: translateX(-130px); /* Csak a belső tartalmat tolja balra */
}

footer {
  background-color: rgba(50, 50, 50, 0.8);
  color: #fff;
  text-align: center;
  padding: 20px;
  width: 100%;
  border-top: 2px solid #333;
  font-size: 16px;
  font-weight: 600;
}


.homepage-header {
  background-color: rgba(255, 204, 0, 0.8);
  padding: 25px;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border: 1px solid #810606;
}

.content {
  flex: 1;
  margin-top: 30px;
}

.main-content {
  margin-bottom: 40px;
}

.central-card-container {
  display: flex;
  gap: 60px;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
  margin-bottom: 40px;
}

.central-card {
  background-color: #b0b0b0;
  border-radius: 10px;
  padding: 5px 7px;
  width: calc(30% - 30px);
  height: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  position: relative;
  border: 2px solid #333;
}

.central-card h4 {
  font-size: 23px;
  margin-bottom: 15px;
  color: #333;
  font-weight: bold;
}

.central-card p {
  font-size: 20px;
  color: #555;
}

.card-container {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  flex-wrap: wrap;
}

.info-card {
  background-color: #f0f0f0;
  border-radius: 10px;
  padding: 20px;
  width: calc(33.333% - 20px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  position: relative;
  border: 2px solid #333;
}

.info-card h4 {
  font-size: 30px;
  margin-bottom: 10px;
  color: #333;
  font-weight: bold;
}

.info-card p {
  font-size: 18px;
  color: #555;
  margin-bottom: 10px;
}

.card-link {
  color: #007bff;
  font-weight: bold;
  text-decoration: none;
  position: absolute;
  bottom: 20px;
}

.card-link:hover {
  text-decoration: underline;
}

.sub-card-container {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.sub-card {
  background-color: #f0f0f0;
  border-radius: 10px;
  padding: 10px;
  width: 45%;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
  border: 1px solid #333;
}

.sub-card:hover {
  transform: scale(1.05);
}

.sub-card p {
  font-size: 17px;
  color: #333;
  font-weight: bold;
}

.main-content h2 {
  font-size: 28px;
  font-weight: bold;
  color: #333;
}

.main-content p {
  font-size: 18px;
  font-weight: 600;
  color: #555;
}

/* Mobilbarát kialakítás kisebb kijelzőkhöz */
@media (max-width: 768px) {
  .info-card,
  .central-card {
    width: 100%; /* Minden kártya teljes szélességű */
    margin-bottom: 20px;
  }

  .homepage-header {
    padding: 15px; /* Kisebb fejléc padding mobilon */
  }

  .content {
    margin-top: 20px;
  }

  .content-wrapper {
    transform: translateX(0); /* Balra tolás eltávolítása mobilon */
  }
}

@media (max-width: 480px) {
  .homepage-container {
    padding: 15px; /* Kisebb padding mobilon */
  }

  .card-container,
  .central-card-container {
    gap: 20px; /* Szorosabb elrendezés kisebb képernyőn */
    flex-direction: column; /* Kártyák egymás alatt */
  }
}
