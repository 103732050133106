/* Google Fonts: válaszd ki a következő betűket: 'Poppins', 'Quicksand' */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&family=Quicksand:wght@400;700&display=swap');

/* Alap stílusok */
body {
  font-family: 'Quicksand', sans-serif; /* Játékos és lekerekített stílus */
  font-size: 17px;
  color: #333;
  line-height: 1.6;
  background-color: #f7f7f7;
  margin: 0;
  padding: 0;
}

/* Címsorok */
h1, h2, h3 {
  font-family: 'Poppins', sans-serif; /* Letisztult, de még mindig játékos */
  font-weight: 600;
  color: #2c3e50;
}

h1 { font-size: 2.5em; margin-bottom: 0.4em; }
h2 { font-size: 2em; margin-bottom: 0.3em; }
h3 { font-size: 1.5em; margin-bottom: 0.3em; }

/* Törzsszöveg */
p {
  font-size: 1rem;
  color: #4d4d4d;
  line-height: 1.8;
  margin-bottom: 1.2em;
}

/* Linkek */
a {
  color: #0077cc;
  text-decoration: underline;
  font-weight: bold;
  font-family: 'Poppins', sans-serif;
  transition: color 0.3s ease-in-out;
}

a:hover {
  color: #005499;
}

/* Navigációs elemek és gombok */
.nav-item, button {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
}

button {
  background-color: #ff8c42;
  color: #ffffff;
  padding: 12px 18px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 0.9rem;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, transform 0.1s;
}

button:hover {
  background-color: #e0773b;
  transform: translateY(-2px);
}



/* Dark mode alapstílusok */
body.dark-mode {
  background-color: #1e1e1e;
  color: #ffffff;
}

/* Main container */
.App, .AddExtraFeatures {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  max-width: 100%;
}

/* Alap kártyastílus */
.card {
  background-color: white;
  border-radius: 10px;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}

body.dark-mode .card {
  background-color: #2a2a2a;
  color: #ffffff;
}

h1 {
  color: #333;
}

body.dark-mode h1 {
  color: #ffffff;
}

/* Form stílusok */
.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #000;
}

body.dark-mode label {
  color: #ffffff;
}

input, select, textarea {
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  color: #000;
}

body.dark-mode input,
body.dark-mode select,
body.dark-mode textarea {
  background-color: #3a3a3a;
  color: #ffffff;
  border: 1px solid #555;
}

/* Gomb stílusok */
button {
  background-color: #28a745;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

button:hover {
  background-color: #218838;
}

.btn-secondary {
  background-color: #6c757d;
}

.btn-secondary:hover {
  background-color: #5a6268;
}

p {
  margin-top: 20px;
  font-size: 18px;
  color: #555;
}

body.dark-mode p {
  color: #cccccc;
}

/* Törlésre került részek:
- .navbar
- .left-section és .right-section
- .metamask-connect és MetaMask logo kapcsolódó stílusok
- dark-mode-toggle kapcsolódó stílusok
- sidebar és social-media-icons stílusok
- FAQ-container kapcsolódó stílusok */

@media (max-width: 768px) {
  .single-post-container {
    padding: 15px;
    max-width: 100%;
  }
  .page-button {
    padding: 8px 10px;
    margin: 5px;
  }
}
body {
  background: radial-gradient(circle at center, #f5f5f5, #e0e0e0 80%, #d6d6d6);
}

